import {
  Contract,
  usePatchContractDetailsMutation,
} from '@cooltra/station-based-api';
import { getErrorStatus } from '@cooltra/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { captureMessage } from '@sentry/react';
import { format } from 'date-fns';
import { Card } from '@cooltra/ui';

import { ContractChannel, ContractPendingSignAlert } from '~/common';
import { useContractFlags, useNotification } from '~/hooks';
import { allContractTypes, getContractTypeName } from '~/utils/contract';
import { usePreferredServicePoints } from '~/libs/station-based/preferred-station-based-service-points';

import {
  ContractDetailsFormValues,
  getInitialContractDetailsFormValues,
  validateContractDetailsForm,
} from '../contract-details-form';
import { SaveContractDetails } from '../SaveContractDetails/SaveContractDetails';
import { ReturnServicePoint } from '../ReturnServicePoint/ReturnServicePoint';
import { ContractDates } from '../ContractDates';
import { DeliveryServicePoint } from '../DeliveryServicePoint/DeliveryServicePoint';

import messages from './messages';

export type ContractDetailsProps = {
  contract: Contract;
};

export const ContractDetails = ({ contract }: ContractDetailsProps) => {
  const intl = useIntl();

  const { contractId, status } = contract;

  const { mutateAsync, isSuccess } =
    usePatchContractDetailsMutation(contractId);

  const { addErrorNotification } = useNotification();

  const { preferredServicePoints: savedServicePoints } =
    usePreferredServicePoints();

  const handleSubmit = (
    values: ContractDetailsFormValues,
    { resetForm }: FormHelpers<ContractDetailsFormValues>
  ) => {
    const typeKey =
      allContractTypes.find(
        (contractType) => getContractTypeName(contractType) === values.type
      ) || values.type;

    return mutateAsync({
      type: typeKey,
      servicePointId: values.pickup!,
      startDate: format(values.pickUpDate!, 'yyyy-MM-dd'),
      startTime: values.pickUpTime!,
      endDate: format(values.dropOffDate!, 'yyyy-MM-dd'),
      endTime: values.dropOffTime!,
    })
      .then(() => resetForm(values))
      .catch((e) => {
        let errorNotification;
        captureMessage('ERROR_UPDATING_CONTRACT_DETAILS', {
          extra: {
            error: e,
            contract,
          },
        });
        switch (getErrorStatus(e)) {
          case 409:
            errorNotification = intl.formatMessage(messages.conflict);
            break;
        }
        return addErrorNotification(errorNotification);
      });
  };

  const {
    areDetailsEditable,
    hasPendingTicket,
    canExtendContract,
    isSignedOrPendingSignature,
  } = useContractFlags();

  const hasPendingAnnex = contract.annexes?.some(
    (annex) =>
      annex.status === 'PENDING_SIGN' ||
      (annex.type !== 'EXTENSION' && annex.status !== 'SIGNED')
  );

  const disabled = isSignedOrPendingSignature || !areDetailsEditable;

  const shouldReset = status === 'BOOKED' && !hasPendingTicket;

  return (
    <div data-testid="CONTRACT_DETAILS">
      <FormProvider
        initialValues={getInitialContractDetailsFormValues(
          contract,
          savedServicePoints.length == 1 ? savedServicePoints[0] : undefined
        )}
        onSubmit={handleSubmit}
        validate={validateContractDetailsForm(intl)}
      >
        <Form>
          <div className="mx-auto max-w-4xl pt-4 pb-10">
            <ContractPendingSignAlert />
            <ContractChannel disabled={disabled} />
            <div className="flex flex-row gap-4 w-full pt-8 pb-14">
              <div className="w-full">
                <h2 className="text-xl text-neutral-700 mb-4 ">
                  <FormattedMessage {...messages.delivery} />
                </h2>
                <Card className="pt-8 pb-14 px-14">
                  <DeliveryServicePoint
                    disabled={disabled}
                    shouldReset={shouldReset}
                  />
                </Card>
              </div>
              <ReturnServicePoint />
            </div>

            <ContractDates
              contractStatus={status}
              disabledDelivery={disabled}
              disabledReturn={
                disabled && (!canExtendContract || hasPendingAnnex)
              }
            />
          </div>
          <SaveContractDetails contractStatus={status} isSuccess={isSuccess} />
        </Form>
      </FormProvider>
    </div>
  );
};
