import { defineMessages } from 'react-intl';
import { PenaltyName } from '@cooltra/utils';

export default defineMessages({
  penalties: {
    defaultMessage: 'Penalties',
  },
  reload: {
    defaultMessage: 'Reload',
  },
  error: {
    defaultMessage: 'There has been a problem loading contract penalties',
  },
  added: {
    defaultMessage: 'Added',
  },
});

export const penaltiesMessages = defineMessages<PenaltyName>({
  LOST_SEAT: {
    defaultMessage: 'Lost seat',
  },
  LOST_KEYS_PADLOCK: {
    defaultMessage: 'Lost keys padlock',
  },
  LOST_OR_DAMAGED_BASKET: {
    defaultMessage: 'Lost or damaged basket',
  },
  LOST_OR_DAMAGED_1ST_HELMET: {
    defaultMessage: 'Lost or damaged 1st helmet',
  },
  LOST_LOCK: {
    defaultMessage: 'Lost lock',
  },
  FINES: {
    defaultMessage: 'Fines',
  },
  LOST_DOCUMENTATION: {
    defaultMessage: 'Lost documentation',
  },
  LOST_KEYS: {
    defaultMessage: 'Lost keys',
  },
  TOW_TRUCK: {
    defaultMessage: 'Town truck',
  },
  LATE_RETURN: {
    defaultMessage: 'Late return',
  },
  DIRTY_VEHICLE: {
    defaultMessage: 'Dirty vehicle',
  },
  LOST_CHARGER: {
    defaultMessage: 'Lost charger',
  },
  LOST_EXTRAS: {
    defaultMessage: 'Lost extras',
  },
  ADMINISTRATIVE_COSTS: {
    defaultMessage: 'Administrative costs',
  },
  FUEL_PENALTY: {
    defaultMessage: 'Fuel penalty',
  },
  RETURN_IN_A_DIFFERENT_POINT: {
    defaultMessage: 'Return in a different point',
  },
  MUNICIPAL_TOW_TRUCK_REMOVAL: {
    defaultMessage: 'Municipal tow truck removal',
  },
});
